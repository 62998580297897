import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Layout, RandomOp, Typography } from '../../components';
import {
  MainTitle, MainPart, Figure, FigureDouble, List,
} from '../../components/opComponents';

const ArthroseDuPoignet = () => (
  <Layout
    title="Arthrose du poignet : Diagnostic et traitement"
    description="L'apparition de l'arthrose au niveau du poignet est le plus souvent insidieuse. Elle peut être révélée par un traumatisme (chute, choc direct.) ou un surmenage du poignet."
    headerStyle="white"
  >
    <div className="flex flex-col px-4 py-16 mx-auto space-y-4 max-w-screen-mdx">
      <Link to="/chirurgie-de-la-main" className="flex flex-row items-center space-x-3 uppercase text-primary group">
        <FontAwesomeIcon className="transition-all duration-300 ease-in-out group-hover:text-link" icon="long-arrow-alt-left" />
        <Typography className="group-hover:text-link" weight="semiBold" transition="medium">Retour aux opérations de la main</Typography>
      </Link>
      <div className="flex flex-col space-y-6">
        <MainTitle currentOpTitle="Arthrose du poignet" />
        <MainPart title="Qu'est-ce que l'arthrose du poignet ?">
          <Typography weight="bold">
            L&apos;arthrose
            {' '}
            <Typography variant="span">est définie par la destruction du cartilage articulaire. Il faut la différencier de l&apos;arthrite qui est une atteinte articulaire secondaire à une pathologie inflammatoire (polyarthrite rhumatoïde, rhumatisme psoriasique...) ou infectieuse.</Typography>
            {' '}
            Au niveau du poignet, l&apos;arthrose
            {' '}
            <Typography variant="span">est le plus souvent post-traumatique et se présente sous deux formes principales :</Typography>
          </Typography>
          <List>
            <List.Item>
              <Typography>
                Secondaire à une rupture du ligament scapho-lunaire : le ligament scapho-lunaire
                est le principal ligament stabilisateur du poignet. La rupture complète de ce
                ligament est responsable d&apos;une déstabilisation du poignet,
                elle-même pourvoyeuse
                d&apos;arthrose. Cette arthrose a été décrite la première fois par Watson en 1984
                sous le terme de SLAC Wrist (Scapho-Lunate Advanced Collapse).
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Secondaire à une pseudarthrose (défaut de consolidation) du scaphoïde : justifiant
                l&apos;intérêt de la prise en charge précoce des pseudarthroses du scaphoïde
                (cf. chapitre spécifique). Par analogie, le terme de SNAC Wrist
                (Scaphoid Non-Union Advance Collapse) a été proposé pour les poignets
                arthrosiques issus d&apos;une pseudarthrose du scaphoïde.
              </Typography>
            </List.Item>
          </List>
        </MainPart>
        <MainPart title="Comment est constitué le poignet ?">
          <div className="flex flex-col">
            <Typography weight="bold">
              Le poignet permet d&apos;établir la connexion entre l&apos;avant-bras et la main.
              {' '}
              <Typography variant="span">
                Sur le plan osseux, il est constitué de la partie inférieure
                des deux os de l&apos;avant-bras (radius et ulna) et des os du carpe.
              </Typography>
            </Typography>
            <Typography>
              Le carpe est quant à lui formé de huit os (Figure 2),
              disposés en deux rangées de quatre os :
            </Typography>
          </div>
          <List>
            <List.Item>
              <Typography>
                Rangée proximale : scaphoïde, semi-lunaire, triquétrum et pisiforme
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Rangée distale : trapèze, trapézoïde, capitatum (grand os) et hamatum (os crochu)
              </Typography>
            </List.Item>
          </List>
          <Figure
            imageName="figure1-arthrosedupoignet.jpg"
            caption="Figure 1. Anatomie osseuse du poignet"
            currentOpTitle="Arthrose du poignet"
          />
        </MainPart>
        <MainPart title="Quels sont les symptômes de l'arthrose du poignet ?">
          <div className="flex flex-col">
            <Typography>
              L&apos;apparition de
              {' '}
              <Typography variant="span" weight="bold">
                l&apos;arthrose au niveau du poignet
              </Typography>
              {' '}
              est le plus souvent insidieuse. Elle peut être révélée par
              un traumatisme (chute, choc direct...) ou un surmenage du poignet.
            </Typography>
            <Typography>
              A l&apos;interrogatoire, il faut rechercher un antécédent
              d&apos;«entorse» du poignet ou
              de fracture du scaphoïde. Dans certains cas,
              aucune notion de traumatisme n&apos;est retrouvée !
            </Typography>
            <Typography>
              Les principaux signes cliniques faisant évoquer l&apos;arthrose du poignet sont :
            </Typography>
          </div>
          <List>
            <List.Item>
              <Typography weight="bold">
                La douleur articulaire :
                {' '}
                <Typography variant="span">
                  liée à la destruction du cartilage
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                Le gonflement de l&apos;articulation :
                {' '}
                <Typography variant="span">
                  lié à un gonflement de la synoviale et à la
                  production excessive de liquide articulaire
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                La perte de mobilité articulaire  :
                {' '}
                <Typography variant="span">
                  secondaire à la destruction de l&apos;articulation, elle
                  peut concerner la flexion et/ou l&apos;extension du poignet
                </Typography>
              </Typography>
            </List.Item>
          </List>
        </MainPart>
        <MainPart title="Quels sont les examens qui permettent de confirmer le diagnostic d'arthrose du poignet ?">
          <List>
            <List.Item>
              <Typography>
                Les radiographies du poignet (face et de profil) : permettent de visualiser
                une pseudarthrose du scaphoïde, une instabilité du poignet et l&apos;arthrose
                (Figure 3).
              </Typography>
            </List.Item>
          </List>
          <Figure
            maxWidth="max-w-[250px]"
            imageName="figure2-arthrosedupoignet.jpg"
            caption="Figure 2. Séquelles de rupture du ligament  scapho-lunaire: apparition d&apos;un diastasis (écart)  scapho-lunaire associé à une arthrose radio-scaphoidienne"
            currentOpTitle="Arthrose du poignet"
          />
          <List>
            <List.Item>
              <Typography>
                L&apos;arthro-scanner : un produit de contraste à base d&apos;iode
                est injecté dans le
                poignet quelques minutes avant la réalisation du scanner. Cet examen
                incontournable est le « gold standard » pour l&apos;analyse complète de
                l&apos;articulation du poignet (os, cartilage et ligaments).
              </Typography>
            </List.Item>
          </List>
        </MainPart>
        <MainPart title="Quels sont les traitements médicaux et chirurgicaux pour l'arthrose du poignet ?">
          <Typography weight="bold">
            Le traitement médical :
            {' '}
            <Typography variant="span">
              indiqué dans les formes peu sévères d&apos;arthrose, il comporte :
            </Typography>
          </Typography>
          <List>
            <List.Item>
              <Typography>
                Les antalgiques
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Les anti-inflammatoires non stéroïdiens : à utiliser sur une courte durée
                en raison de nombreux effets secondaires (ulcère gastrique, insuffisance rénale...)
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Attelle : en cas de crises douloureuse, à mettre la nuit
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Injection articulaire de corticoïde : le plus souvent d&apos;efficacité temporaire
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Injection articulaire d&apos;acide hyaluronique : son efficacité n&apos;a pas
                été démontrée dans les articulations de la main
              </Typography>
            </List.Item>
          </List>
          <Typography weight="bold">
            Le traitement chirurgical :
            {' '}
            <span className="flex flex-col">
              <Typography variant="span">
                indiqué en cas d&apos;échec du traitement médical et/ou d&apos;emblée
                dans les formes sévères d&apos;arthrose. Son principe est de supprimer
                l&apos;interligne articulaire arthrosique à l&apos;origine des douleurs.
              </Typography>
              <Typography variant="span">
                L&apos;intervention est le plus souvent réalisée sous anesthésie locorégionale
                (le bras est uniquement endormi) et en ambulatoire (pas d&apos;hospitalisation).
                L&apos;abord chirurgical est fait par une incision sur le dos du poignet.
              </Typography>
              <Typography variant="span">
                Le poignet est dans un premier temps dénervé afin de diminuer les douleurs,
                l&apos;articulation est ensuite exposée.
              </Typography>
              <Typography variant="span">
                Les principales interventions chirurgicales sont :
              </Typography>
            </span>
          </Typography>
          <List>
            <List.Item>
              <Typography>
                Résection de la première rangée des os du carpe : le scaphoïde, le semi-lunaire
                et le triquétrum sont retirés (Figure 4). Une nouvelle articulation entre le
                radius et la tête du capitatum est ainsi créée (Figure 5).
              </Typography>
            </List.Item>
          </List>
          <div className="flex flex-col items-start space-x-0 space-y-8 mdx:flex-row mdx:space-x-8 mdx:space-y-0">
            <Figure
              maxWidth="max-w-[400px]"
              imageName="figure3-arthrosedupoignet.jpg"
              caption="Figure 3. Résection de la première rangée du carpe (de droite à gauche : scaphoïde, semi-lunaire et triquétrum)"
              currentOpTitle="Arthrose du poignet"
            />
            <Figure
              maxWidth="max-w-[200px]"
              imageName="figure4-arthrosedupoignet.jpg"
              caption="Figure 2. Résultat d&apos;une résection de la première rangée du carpe avec création d&apos;une nouvelle articulation entre le capitatum et le radius"
              currentOpTitle="Arthrose du poignet"
            />
          </div>
          <Typography>
            En cas d&apos;arthrose médio-carpienne, un implant de
            resurfaçage est mis en place sur le capitatum (Figure 6).
          </Typography>
          <FigureDouble
            maxWidth="max-w-[300px]"
            height="h-[150px]"
            imageNameOne="figure5-arthrosedupoignet.jpg"
            imageNameTwo="figure5bis-arthrosedupoignet.jpg"
            caption="Figure 5. Résection de première rangée du carpe avec implant de resurfaçage RCPI"
            currentOpTitle="Arthrose du poignet"
          />
          <List>
            <List.Item>
              Scaphoïdectomie associée à une arthrodèse des quatre os : le scaphoïde est
              retiré et quatre os (semi-lunaire, triquétrum, capitatum et hamatum)
              sont fusionnés à l&apos;aide d&apos;une plaque et des vis (Figure 7 et 8). Une
              greffe osseuse, prélevée le plus souvent sur le radius est nécessaire
              afin d&apos;obtenir la fusion osseuse.
            </List.Item>
          </List>
          <div className="flex flex-col items-start space-x-0 space-y-8 mdx:flex-row mdx:space-x-8 mdx:space-y-0">
            <Figure
              maxWidth="max-w-[400px]"
              imageName="figure6-arthrosedupoignet.jpg"
              caption="Figure 6. Plaque et vis utilisés pour l&apos;arthrodèse des quatre os"
              currentOpTitle="Arthrose du poignet"
            />
            <Figure
              maxWidth="max-w-[250px]"
              imageName="figure7-arthrosedupoignet.jpg"
              caption="Figure 7. Résultat d&apos;une scaphoïdectomie associée à une arthrodèse des quatre os"
              currentOpTitle="Arthrose du poignet"
            />
          </div>
          <Typography>
            Le choix entre ces deux techniques dépend de plusieurs facteurs : l&apos;âge et
            la profession du patient, la présence d&apos;une arthrose médio-carpienne et
            les habitudes du chirurgien.
          </Typography>
          <List>
            <List.Item>
              <Typography>
                L&apos;arthrodèse totale (blocage complet) du poignet est en
                général proposée en dernier
                recours, après échec d&apos;une première chirurgie (résection de première rangée ou
                arthrodèse des quatre os). Lors de cette intervention, le cartilage résiduel
                est retiré et le poignet est ensuite bloqué définitivement
                par une plaque et des vis.
              </Typography>
            </List.Item>
          </List>
        </MainPart>
        <MainPart title="Quelles sont les suites post-opératoires après une opération chirurgicale pour l'arthrose du poignet ?">
          <Typography>
            Dans les suites post-opératoires, des soins sont réalisés au niveau de
            la cicatrice tous les deux jours, les fils sont retirés à J14.
            {' '}
            <Typography variant="span" weight="bold">
              Le poignet est immobilisé pour une période de 4 à 6 semaines
            </Typography>
            {' '}
            La rééducation est initiée à l&apos;ablation de l&apos;attelle
            et est poursuivie sur une période de 6 à 12 mois.
          </Typography>
        </MainPart>
        <MainPart title="Quelles sont les complications possibles après une opération chirurgicale pour de l'arthrose du poignet ?">
          <Typography>
            Il n&apos;existe pas d&apos;intervention chirurgicale sans risque de complications
            secondaires. Les complications peuvent être classées en deux catégories :
          </Typography>
          <Typography size="mdx" weight="bold">Complications non spécifiques :</Typography>
          <List>
            <List.Item>
              <Typography>
                Infection du site opératoire pouvant nécessiter une
                intervention de nettoyage associée à la prise d&apos;antibiotiques
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Hématome pouvant nécessiter une évacuation en cas
                de menace cutanée ou compression nerveuse
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Algodystrophie. Son apparition est indépendante du type de chirurgie.
                Elle évolue en deux phases : phase chaude (main gonflée, douloureuse
                avec transpiration) puis froide (prédominance de la raideur). L&apos;évolution
                est le plus souvent longue (12-18 mois) et des séquelles sont
                possibles (douleur et/ou raideur des articulations des
                doigts et/ou poignet et/ou épaule)
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Accidents liés à l&apos;anesthésie
              </Typography>
            </List.Item>
          </List>
          <Typography size="mdx" weight="bold">Complications spécifiques :</Typography>
          <List>
            <List.Item>
              <Typography>
                Perte de force : plus fréquemment observée en cas
                de résection de la première rangée du carpe. Chez un
                travailleur de force, il est plutôt conseillé de réaliser une
                arthrodèse des quatre os.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Perte de mobilité : principale conséquence de la chirurgie du poignet
                (résection de la première rangée et arthrodèse des quatre os). Les mobilités
                post-opératoires en flexion et extension sont de l&apos;ordre de 30 à 40°
                (arc de mobilité de 60 à 80°).
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Échec de l&apos;arthrodèse : absence de consolidation osseuse nécessitant le plus
                souvent une chirurgie de reprise avec greffe osseuse prélevée sur le bassin.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Usure de la tête du capitatum : peut survenir plusieurs années après une résection
                de la première rangée du carpe. En cas de douleurs, une reprise chirurgicale avec
                mise en place d&apos;un implant de resurfaçage sur le capitatum peut être envisagée.
              </Typography>
            </List.Item>
          </List>
        </MainPart>
      </div>
    </div>
    <RandomOp type="mains" />
  </Layout>
);

export default ArthroseDuPoignet;
